<template>
  <div
    class="h100 d-flex flex-column justify-content-center align-items-center"
  >
    <h1>ERROR</h1>
    <div>{{ message }}</div>
    <div class="mt-5">{{ subMessage }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    message() {
      return this.$route?.params?.message ?? ''
    },
    subMessage() {
      return this.$route?.params?.subMessage ?? ''
    }
  }
}
</script>
